/**
 * Use this function to get meta values as route may match multiple route
 * records, @see https://router.vuejs.org/guide/advanced/meta.html
 *
 * @param {RouteRecord[]} rs - vue-router route records
 * @param {String} name
 * @returns {*} first value of `meta.name` encountered or `null`
 */
export function getMeta(rs, name) {
  for (const r of rs) {
    if (r.meta[name]) {
      return r.meta[name]
    }
  }
  return null
}
