<template>
  <div class="img-single">
    <img
      class="img-single__img"
      :src="img.src"
      :alt="img.alt"
    >
  </div>
</template>

<script>
import ImgData from "@/models/ImgData.js"

export default {
  props: {
    img: {
      type: ImgData,
      required: true
    }
  }
}
</script>

<styles lang="scss">
@use "img";

.img-single__img {
  display: block;
  width: 100%;
  height: auto;
  $b: img.$gap solid img.$gap__color;
  border-top: $b;
  border-bottom: $b;
}
</styles>
