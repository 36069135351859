<template>
  <section class="veranstaltung">
    <h1 class="veranstaltung__h1">
      {{ content.name }}
    </h1>
    <ParagraphsPrePic :paragraphs="content.descrPrePic" />
    <GalleryResponsive
      v-if="content.imgArr.length > 1"
      class="veranstaltung__gallery"
      :img-arr="content.imgArr"
    />
    <ImgSingle
      v-if="content.imgArr.length == 1"
      class="veranstaltung__gallery"
      :img="content.imgArr[0]"
    />
    <SectionPostPic
      v-for="x in content.postPicContent"
      :key="x.header"
      :content="x"
    />
    <div
      v-if="content.btnLinkExt"
      class="veranstaltung__btnlinkwrap"
    >
      <BtnLinkExt :link="content.btnLinkExt" />
    </div>
    <section>
      <SectionPostPic
        v-if="content.sectionContact"
        :content="content.sectionContact"
      />
      <div class="veranstaltung__contactlink">
        <LinkTel />
      </div>
      <div class="veranstaltung__contactlink">
        <LinkEmail />
      </div>
    </section>
  </section>
</template>

<script>
import BtnLinkExt from "@/components/BtnLinkExt.vue"
import {content} from "@/models/Veranstaltung.js"
import {getMeta} from "@/util/route.js"
import GalleryResponsive from "@/components/GalleryResponsive.vue"
import ImgSingle from "@/components/ImgSingle.vue"
import LinkTel from "@/components/LinkTel.vue"
import LinkEmail from "@/components/LinkEmail.vue"
import ParagraphsPrePic from "@/components/ParagraphsPrePic.vue"
import SectionPostPic from "@/components/SectionPostPic.vue"

export default {
  components: {
    BtnLinkExt,
    GalleryResponsive,
    ImgSingle,
    LinkTel,
    LinkEmail,
    ParagraphsPrePic,
    SectionPostPic
  },

  computed: {
    veranstaltungenKey() {
      return getMeta(this.$route.matched, "veranstaltungenKey")
    },

    content() {
      return content[this.veranstaltungenKey]
    }
  }
}
</script>

<styles lang="scss">
@use "app";
@use "txt";

.veranstaltung {
  margin-top: app.$margin-to-nav;
  margin-bottom: txt.$blockmargin;
}

.veranstaltung__h1 {
  @include txt.sitehead;
  @include txt.bodypadding-h;
}

.veranstaltung__gallery {
  margin-top: 3em;
}

.veranstaltung__btnlinkwrap {
  margin-top: 2em;
  text-align: center;
}

.veranstaltung__contactlink {
  text-align: center;
}
</styles>
