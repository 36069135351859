<template>
  <div class="paragraphs-pre-pic">
    <!-- eslint-disable vue/no-v-html -->
    <p
      v-for="p in paragraphs"
      :key="p"
      v-html="p"
    />
    <!-- eslint-enable vue/no-v-html -->
  </div>
</template>

<script>
export default {
  props: {
    paragraphs: {
      type: Array, // String[]
      required: true
    }
  }
}
</script>

<styles lang="scss">
@use "txt";
@use "vars";

.paragraphs-pre-pic {
  max-width: vars.$max-content-w-1;
  margin: 1.5em auto 0 auto;
  @include txt.bodypadding-h;

  p {
    margin-top: 1em;
    text-align: center;
    &:first-of-type {
      margin-top: 0;
    }
  }
}
</styles>
