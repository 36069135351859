<template>
  <a
    class="link-email"
    :href="`mailto:${email}`"
  >
    <slot>{{ email }}</slot>
  </a>
</template>

<script>
export default {
  props: {
    email: {
      type: String,
      default: "5@5.fo"
    }
  }
}
</script>
