import {Content as PostPicContent} from "@/components/SectionPostPic.vue"
import ImgData from "@/models/ImgData.js"
import LinkDataExt from "@/models/LinkDataExt.js"

export default class Veranstaltung {
  /**
   * @param {Object} x
   * @param {String} x.name
   * @param {String[]} x.descrPrePic - paragraphs, rendered with v-html
   * @param {ImgData[]} x.imgArr
   * @param {PostPicContent[]} x.postPicContent
   * @param {LinkDataExt|undefined} x.btnLinkExt
   * @param {PostPicContent|undefined} x.sectionContact
   */
  constructor(x) {
    Object.assign(this, x)
  }
}

const linkEventMap = new LinkDataExt({
  txt: "Grundriss",
  href: "/img/event_map.jpg",
  target: "_blank"
})

const sectionContact = new PostPicContent({
  header: "Das 5 Team informiert Sie gerne:",
  headingLevel: 2,
  paragraphs: [
    "Anfragen telefonisch oder per E-Mail"
  ]
})

const optsBGPosTop = {
  bgPos: "top"
}

// Maps /veranstaltungen/:name to content
export const content = {
  "ihre-veranstaltung-im-5": new Veranstaltung({
    name: "Feiern hoch 5",
    descrPrePic: [
      "Sie möchten eine Firmenfeier, ein Jubiläum oder ein anderes exklusives Event ausrichten?<br>Wir freuen uns, Ihre Veranstaltung in ein maßgeschneidertes Gewand zu kleiden."
    ],
    imgArr: [
      new ImgData("/img/event1.jpg", "Gäste im Wohnraum"),
      new ImgData("/img/event2.jpg", "Fleisch auf braunem Teller"),
      new ImgData("/img/event3.jpg", "Ansprache"),
      new ImgData("/img/event1.jpg", "Event im Wohnraum"),
      new ImgData("/img/event2.jpg", "Speisen auf braunem Teller")
    ],
    postPicContent: [
      new PostPicContent({
        header: "Ihre Veranstaltung im 5",
        headingLevel: 2,
        paragraphs: [
          "Egal, ob ein gesetztes Dinner in unserem 5 Gourmet Restaurant mit bis zu 45 Gästen oder in unserem Bar-Lounge-Bereich mit bis zu 60 Gästen, das 5 ist der perfekte Ort im Herzen der Innenstadt.<br>Bei größeren Veranstaltungen für bis zu 200 Gäste verwöhnt Sie unser Sternekoch & sein Team im Restaurant (1. OG) und in der Bar/Lounge (EG) mit einem Flying Service.",

          "Egal, ob eine Reise durch die Sterneküche oder eine kulinarische Reise durch die Region, wir können Ihnen kulinarisch jeden Wunsch erfüllen.<br>Zahlreiche Firmen- und Privatveranstaltungen haben wir zu einem besonderen Erlebnis gemacht und stets begeisterte Gäste zurückgelassen."
        ]
      }),
      new PostPicContent({
        header: "Facts & Figures",
        headingLevel: 3,
        paragraphs: [
          "Sie können die verschiedenen Bereiche einzeln oder zusammen buchen, um Ihrer Veranstaltung den perfekten Rahmen zu geben.",
          "Auf 236 qm Gesamtfläche bieten wir:<br>Sitzend finden bis zu 120 Gäste und sitzend/stehend 200 Gäste Platz.<br>Ob gesetztes Diner oder Flying Service – kulinarisch können wir Ihnen jeden Wunsch erfüllen.<br>Beamer, Bildschirme und Mikrofon sind selbstverständlich vorhanden und vorinstalliert."
        ]
      })
    ],
    btnLinkExt: linkEventMap,
    sectionContact
  }),
  "heiraten-im-5": new Veranstaltung({
    name: "Heiraten im 5",
    descrPrePic: [
      "Ein stilvolles Hochzeitsdinner im 5 Gourmet Restaurant oder eine unvergessliche Hochzeitsparty in der 5 Bar."
    ],
    imgArr: [
      new ImgData("/img/marry3.jpg", "Braut auf der Treppe", optsBGPosTop),
      new ImgData("/img/marry2.jpg", "Blumen und Früchte"),
      new ImgData("/img/marry4.jpg", "Tischgedeck"),
      new ImgData("/img/marry5.jpg", "Braut vor gedecktem Tisch", optsBGPosTop),
      new ImgData("/img/marry1.jpg", "Kerzen und Blumenkranz")
    ],
    postPicContent: [
      new PostPicContent({
        header: "„Hoch“zeiten der Ehe",
        headingLevel: 2,
        paragraphs: [
          "Sie träumen, den schönsten Tag Ihres Lebens in der angenehm trauten Atmosphäre eines exklusiven Gourmet Restaurants gedeihen zu lassen, möchten Ihr Glück im fröhlichen Ambiente einer kunstgerechten Bar voll und ganz genießen?",
          "Im kultivierten Zusammenspiel zwischen Sternerestaurant, Bar und Wohlfühloase schöpft das 5 in den altehrwürdigen Gemäuern des ersten Stuttgarter Bahnhofs die perfekte Atmosphäre, die Hochzeit Ihrer Ehe stilvoll zu feiern."
        ]
      }),
      new PostPicContent({
        header: "Facts & Figures",
        headingLevel: 3,
        paragraphs: [
          "40 Gäste finden bei einem „Seated Dinner“ im aparten Sternerestaurant auf Rosen gebettet ihren Platz an der Sonne.<br>Mit unserem herzerfrischenden „Flying Service“ verwöhnen wir bis zu 200 Hochzeitsgäste mit delikaten Speisen und erlesenen Getränken.",
          "Sie wünschen – wir spielen.<br>Ein großzügiges Portfolio exzellenter DJs und ausgezeichneter Live-Musiker steht bereit, Ihren Feiertag klangvoll zu untermalen.<br>Wir geben unser Bestes, damit der schönste Tag Ihres Lebens der schönste Tag Ihres Lebens wird."
        ]
      })
    ],
    btnLinkExt: linkEventMap,
    sectionContact
  }),
  "private-cooking": new Veranstaltung({
    name: "Private Cooking",
    descrPrePic: [
      "Buchen Sie unseren Sternekoch Alexander Dinter für Ihre Veranstaltung zu Hause."
    ],
    imgArr: [
      new ImgData("/img/pcook5.jpg", "Alexander Dinter", optsBGPosTop),
      new ImgData("/img/pcook1.jpg", "5 Logo"),
      new ImgData("/img/pcook2.jpg", "Fisch mit Gemüse"),
      new ImgData("/img/pcook3.jpg", "Gericht in goldenem Teller"),
      new ImgData("/img/pcook4.jpg", "Fleisch auf schwarzem Teller")
    ],
    postPicContent: [
      new PostPicContent({
        header: "Facts & Figures",
        headingLevel: 2,
        paragraphs: [
          "Aus Ihrer Küche wird eine Sterneküche und Sie verwöhnen Ihre Gäste zu Hause auf höchstem Niveau.",
          "Wenn Sie es wünschen, können Sie auch unseren Sommelier buchen und mit Ihren Gästen eine Weinreise in den eigenen Räumlichkeiten unternehmen."
        ]
      })
    ],
    btnLinkExt: undefined,
    sectionContact
  }),
  kochkurse: new Veranstaltung({
    name: "Kochkurse",
    descrPrePic: [
      "Kochen mit einem Sternekoch."
    ],
    imgArr: [
      new ImgData("/img/class3.jpg", "Kochkurs im 5")
    ],
    postPicContent: [
      new PostPicContent({
        header: "Facts & Figures",
        headingLevel: 2,
        paragraphs: [
          "Unser Küchenchef Alexander Dinter kocht mit einer Gruppe von 12 Personen.",
          "Sie bekommen einen Einblick in verschiedene Themen der Spitzenkulinarik.",
          "Genießen Sie Ihr Menu in 3 Gängen mit Gleichgesinnten."
        ]
      })
    ],
    btnLinkExt: undefined,
    sectionContact
  })
}
