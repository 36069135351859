<template>
  <section class="section-post-pic">
    <component
      :is="headingTagName"
      class="header"
    >
      {{ content.header }}
    </component>
    <!-- eslint-disable vue/no-v-html -->
    <p
      v-for="p in content.paragraphs"
      :key="p"
      class="p"
      v-html="p"
    />
    <!-- eslint-enable vue/no-v-html -->
  </section>
</template>

<script>
export class Content {
  /**
   * @param {Object} x
   * @param {String} x.header
   * @param {Number} [x.headingLevel=1] - range 1 to 6
   * @param {String[]} x.parapgraphs
   */
  constructor(x) {
    if (x.headingLevel && (x.headingLevel < 1 || x.headingLevel > 6)) {
      throw new Error("headingLevel must be in range 1 to 6")
    }

    Object.assign(
      this,
      {headingLevel: 1},
      x
    )
  }
}

export default {
  props: {
    content: {
      type: Content,
      required: true
    }
  },

  computed: {
    headingTagName() {
      return `h${this.content.headingLevel}`
    }
  }
}
</script>

<style lang="scss" scoped>
@use "sass:math";

@use "txt";
@use "vars";

.section-post-pic {
  margin-top: txt.$blockmargin;
  @include txt.bodypadding-h;
  text-align: center;

  @media (max-width: vars.$break-mobile) {
    margin-top: 4em;
  }
}

.p {
  margin-top: math.div(txt.$blockmargin, 3);

  @media (max-width: vars.$break-mobile) {
    margin-top: 2em;
  }

  &:first-of-type {
    margin-top: 1em;
  }
}

.header {
  color: vars.$gold;

  /* Make font-size independent of content.headingLevel. */
  font-size: 1.5em;
}
</style>
